<template>
	<section :class="dark ? 'bg-black' : 'bg-white' ">
		<div class="content-area" >
			<div class="lg:flex grid items-center lg:gap-14 gap-6 py-12 justify-center">
				<h2 class="flex items-center font-semibold text-lg leading-[2rem] gap-12 lg:px-0 px-6" :class="dark ? 'text-white ': 'text-black'">
					<span class="rounded-full border border-selective-yellow md:grid place-content-center w-16 h-16 lg:ml-12 hidden">
						<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path :fill="dark ? '#fff': '#000'" d="M7.5835 0.333344C5.66432 0.333344 4.0835 1.91417 4.0835 3.83334C4.0835 5.75252 5.66432 7.33334 7.5835 7.33334C9.50268 7.33334 11.0835 5.75252 11.0835 3.83334C11.0835 1.91417 9.50268 0.333344 7.5835 0.333344ZM19.2502 0.333344C17.331 0.333344 15.7502 1.91417 15.7502 3.83334C15.7502 5.75252 17.331 7.33334 19.2502 7.33334C21.1693 7.33334 22.7502 5.75252 22.7502 3.83334C22.7502 1.91417 21.1693 0.333344 19.2502 0.333344ZM7.5835 2.66668C8.24165 2.66668 8.75016 3.17519 8.75016 3.83334C8.75016 4.49149 8.24165 5.00001 7.5835 5.00001C6.92535 5.00001 6.41683 4.49149 6.41683 3.83334C6.41683 3.17519 6.92535 2.66668 7.5835 2.66668ZM19.2502 2.66668C19.9083 2.66668 20.4168 3.17519 20.4168 3.83334C20.4168 4.49149 19.9083 5.00001 19.2502 5.00001C18.592 5.00001 18.0835 4.49149 18.0835 3.83334C18.0835 3.17519 18.592 2.66668 19.2502 2.66668ZM7.5835 9.66668C5.86097 9.66668 4.27625 9.938 3.01025 10.4824C2.37726 10.7547 1.81686 11.0956 1.36735 11.5693C0.917836 12.0431 0.583496 12.7008 0.583496 13.4196V16.6667H12.2502H14.5835H19.2502V15.1126V14.3333H14.5835V13.4196C14.5835 13.0078 16.218 12 19.2502 12H25.799C25.5377 11.6045 25.1382 11.1941 24.5047 10.8242C23.2447 10.0892 21.3292 9.66668 19.2502 9.66668C16.7056 9.66668 14.6404 10.2593 13.4282 11.2298C13.0543 10.9262 12.6262 10.6843 12.1567 10.4824C10.8907 9.938 9.30602 9.66668 7.5835 9.66668ZM7.5835 12C9.04714 12 10.3798 12.2593 11.2339 12.6266C11.661 12.8103 11.9602 13.0215 12.1066 13.1758C12.253 13.3301 12.2502 13.3754 12.2502 13.4196V14.3333H2.91683V13.4196C2.91683 13.3754 2.91398 13.3301 3.06038 13.1758C3.20678 13.0215 3.50604 12.8103 3.93311 12.6266C4.78724 12.2593 6.11985 12 7.5835 12ZM21.5835 14.3333V17.8333H18.0835V20.1667H21.5835V23.6667H23.9168V20.1667H27.4168V17.8333H23.9168V14.3333H21.5835Z"/>
						</svg>
					</span>
					Simule agora mesmo! Informe seu saldo FGTS:
				</h2>
				<form class="md:border-b border-b-selective-yellow bg-transparent py-2 grid md:grid-cols-[1fr,88px] items-center lg:max-w-[488px] w-full lg:mx-0 mx-auto lg:px-0 px-6">
					<div class="flex items-center lg:border-none lg:p-0 border-b border-b-selective-yellow p-4">
						<span class="text-selective-yellow font-semibold leading-2 md:text-base text-lg">R$</span>
						<input ref="currency" id="currency" data-thousands="." data-decimal="," type="text" class="md:text-base text-md flex-1 currency-input text-white leading-3 bg-transparent px-4 outline-none" placeholder="Ex.: 2.000,00" :class="dark ? 'placeholder:text-[#E8E8E880]': 'placeholder:text-[#6A6A6A]'" />
					</div>
					<button class="block md:grid place-content-center group md:w-fit w-full md:mt-0 mt-3 md:p-0 p-4 rounded-lg bg-selective-yellow" @click.prevent="send()" type="button" :class="dark ? 'md:bg-black' : 'md:bg-white' ">
						<div class="flex items-center gap-2 md:text-selective-yellow font-semibold leading-2 md:text-base text-lg text-white justify-center" >
							<span>Simular</span>
							<svg class="group-hover:rotate-45 transition-all will-change-transform" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path class="md:fill-selective-yellow fill-white" d="M1.0168 11.9999C1.28008 11.9978 1.53189 11.8919 1.71752 11.7052L10.0013 3.40847L10.0013 7.99605C10.0013 8.26114 10.1066 8.51537 10.294 8.70281C10.4814 8.89025 10.7356 8.99556 11.0006 8.99556C11.2657 8.99556 11.5199 8.89025 11.7073 8.70281C11.8947 8.51536 12 8.26114 12 7.99605L12 0.999506C11.9985 0.868598 11.9713 0.739259 11.92 0.618835C11.8186 0.375136 11.6249 0.181447 11.3813 0.0800381C11.2608 0.0286856 11.1315 0.00149162 11.0006 -8.66307e-07L4.00512 -2.5474e-07C3.74007 -2.31568e-07 3.48588 0.105305 3.29846 0.292748C3.11105 0.480192 3.00576 0.734421 3.00576 0.999507C3.00576 1.26459 3.11105 1.51882 3.29846 1.70626C3.48588 1.89371 3.74007 1.99901 4.00512 1.99901L8.59203 1.99901L0.296556 10.284C0.202605 10.3769 0.128019 10.4876 0.0771149 10.6096C0.0262116 10.7315 8.54222e-07 10.8624 8.65777e-07 10.9946C8.77332e-07 11.1267 0.0262117 11.2576 0.077115 11.3796C0.128019 11.5015 0.202605 11.6122 0.296556 11.7052C0.390797 11.8 0.503097 11.8749 0.62681 11.9255C0.750525 11.9762 0.883136 12.0015 1.0168 11.9999Z" />
							</svg>
						</div>
					</button>
				</form>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'QuotationComponent',
	props: {
		dark: {
			type: Boolean,
			default: true
		},
    originTag: String,
	},
  data() {
    return {
      number: '+5548999440887'
    }
  },
	methods: {
		send() {
			let url = `https://api.whatsapp.com/send?phone=${this.number}&app=facebook&entry_point=page_cta`
      const value = `Olá, Gostaria de antecipar meu saldo de FGTS. Saldo FGTS: R$ ${this.$refs.currency.value}`;
      url += `&text=(${this.originTag})${value}`;
      window.open(url, '_blank');
      this.amount = ''
      return false
		}
	},
	mounted() {
		// eslint-disable-next-line no-undef
		$('#currency').maskMoney();
	},
}
</script>